import Button from "primevue/button";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { nhost, module } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole } from "@/service/roles";
import { useGlobalStore } from "@/stores/global-store";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "notFound" },
  },
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    meta: {
      requiresAuth: true,
      requiresPickedTenant: true,
    },
    path: "/",
    children: [
      {
        meta: {
          allowedRoles: [
            "tenant_admin_pp",
            "tenant_admin_pp_bay",
            "pp-manager",
            "pp-requester",
          ],
        },
        path: "/",
        children: [
          {
            path: "/requests",
            name: "requests",
            children: [
              {
                path: "home",
                name: "home",
                component: () =>
                  import("@/views/pages/components/home/Home.vue"),
              },
              {
                path: "create",
                name: "create",
                component: () =>
                  import("@/views/pages/components/request/Request.vue"),
              },
              {
                path: "request-detail/:id",
                name: "request-detail",
                component: () =>
                  import("@/views/pages/components/request/Request.vue"),
              },
              {
                path: "request/:id/lack-delay",
                name: "lack-delay",
                component: () =>
                  import(
                    "@/views/pages/components/request/r-lack-delay/LackDelay.vue"
                  ),
              },
            ],
          },
          {
            path: "/instructions-for-use",
            name: "instructions-for-use",
            component: () =>
              import(
                "@/views/pages/components/instructions-for-use/InstructionsForUse.vue"
              ),
          },
          {
            meta: {
              allowedRoles: ["tenant_admin_pp", "tenant_admin_pp_bay"],
            },
            path: "/settings",
            children: [
              {
                path: "",
                name: "settings",
                component: () =>
                  import("@/views/pages/components/settings/Dashboard.vue"),
              },
              {
                path: "user",
                name: "user",
                component: () =>
                  import("@/views/pages/components/settings/cruds/User.vue"),
              },
              {
                path: "lack-and-delay",
                name: "lackAndDelay",
                component: () =>
                  import(
                    "@/views/pages/components/settings/cruds/LackDelay.vue"
                  ),
              },
              {
                path: "disapproval",
                name: "disapproval",
                component: () =>
                  import(
                    "@/views/pages/components/settings/cruds/Disapproval.vue"
                  ),
              },
              {
                path: "reason-cancel",
                name: "reasonCancel",
                component: () =>
                  import("@/views/pages/components/settings/cruds/Cancel.vue"),
              },
              {
                path: "email-body",
                name: "emailBody",
                component: () =>
                  import(
                    "@/views/pages/components/settings/cruds/EmailBody.vue"
                  ),
              },
              {
                path: "category",
                name: "category",
                component: () =>
                  import(
                    "@/views/pages/components/settings/cruds/Category.vue"
                  ),
              },
              {
                path: "unit",
                name: "unit",
                component: () =>
                  import("@/views/pages/components/settings/cruds/Unit.vue"),
              },
              {
                path: "station",
                name: "station",
                component: () =>
                  import("@/views/pages/components/settings/cruds/Station.vue"),
              },
            ],
          },

          // {
          //   path: "/configuracao",
          //   name: "configuracao",
          //   children: [
          //     {
          //       path: "usuario"
          //       name: "usuario",
          //       component: () =>
          //         import("@/views/pages/components/settings/crud/User.vue"),
          //     },
          //     {
          //       path: "loja"
          //       name: "loja",
          //       component: () =>
          //         import("@/views/pages/components/settings/crud/Store.vue"),
          //     },
          // }
        ],
      },
    ],
  },
  {
    path: "/errors",
    children: [
      {
        path: "/not-found",
        name: "notFound",
        component: () => import("@/components/errors/NotFound.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/under-construction",
        name: "underConstruction",
        component: () => import("@/components/errors/UnderConstruction.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/",
        children: [
          {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@/components/errors/AccessDenied.vue"),
            props: {
              Button,
            },
          },
          {
            path: "/access-denied-m",
            name: "access-denied-m",
            component: () => import("@/components/errors/AccessDeniedM.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  //await nhost.auth.refreshSession()
  const globalStore = useGlobalStore();
  if (from?.fullPath && to.fullPath != from?.fullPath)
    globalStore.hideLoading();

  const requiresAuth = to.meta.requiresAuth;
  const requiresNotAuth = to.meta.requiresNotAuth;
  const requiresNotReg = to.meta.requiresNotReg;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  const isReg =
    nhost.auth.getHasuraClaim("x-hasura-Tenant-User-Id") ||
    nhost.auth.getHasuraClaim("x-hasura-Tenant-ADM-Id");
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  if (["access-denied-m", "access-denied"].includes(to.name.toString()))
    return next();
  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }
  const role = await updateRole();
  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/prevencao-de-perdas#${to.fullPath as string}`
    );
    window.location.href = `/home#/setup/setup-choice${encodedRedirect}`;
    return;
  }
  if (await isUserBlocked()) {
    next({ name: "access-denied-m" });
    return;
  }
  if (allowedRoles && !allowedRoles.includes(role as string)) {
    next({ name: "access-denied" });
    return;
  }

  next();
});

export default router;
