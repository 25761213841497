import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient, nhost } from "@/main";
import { useQuery } from "@vue/apollo-composable";
import { gql } from "graphql-tag";

export const isUserBlocked = async () => {
  const { result, loading, error } = await provideApolloClient(apolloClient)(
    () =>
      useQuery(
        gql`
          query getUserBlockage($id: uuid!) {
            user(id: $id) {
              id
              user_modules(
                where: { module: { module: { _eq: "prevencao_de_perdas" } } }
              ) {
                blocked
                module {
                  module
                }
              }
            }
          }
        `,
        {
          id: nhost.auth.getUser()?.id,
        },
        {
          fetchPolicy: "no-cache",
        }
      )
  );
  while (loading.value) {
    await new Promise((r) => setTimeout(r, 100));
  }
  return (
    result?.value?.user?.user_modules[0]?.blocked == true ||
    result?.value?.user?.user_modules[0]?.blocked == undefined
  );
};
