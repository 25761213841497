import "core-js/modules/web.timers.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "plano-de-acao-container"
};
import { onMounted } from "vue";
import AppLoading from "@/views/pages/components/AppLoading.vue";
import { useNhostClient } from "@nhost/vue";
import { usePrimeVue } from "primevue/config";
import { useI18n } from "vue-i18n";
import packageJSON from "../package.json";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var version = packageJSON.version;
    localStorage.setItem("module_version", version);
    var _useI18n = useI18n(),
      t = _useI18n.t;
    onMounted(function () {
      var primevue = usePrimeVue();
      primevue.config.locale.dayNamesShort = t("locale.dayNamesShort").split(" ");
      primevue.config.locale.dayNamesMin = t("locale.dayNamesMin").split(" ");
      primevue.config.locale.monthNamesShort = t("locale.monthNamesShort").split(" ");
      primevue.config.locale.dayNames = t("locale.dayNames").split(" ");
      primevue.config.locale.monthNames = t("locale.monthNames").split(" ");
      setTimeout(function () {
        document.getElementById("loaderID").style.display = "none";
      }, 4000);
    });
    return function (_ctx, _cache) {
      var _component_Toast = _resolveComponent("Toast");
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Toast), _createElementVNode("main", _hoisted_1, [_createVNode(_component_router_view)]), _createVNode(AppLoading)], 64);
    };
  }
};